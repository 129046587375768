import React, { useState, useContext } from 'react';
import {
  Modal, Grid, Header, List, Label,
} from 'semantic-ui-react';
import moment from 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CustomLink from '../../../CustomLink';
import DataContext from '../../../../contexts/DataContext';

const today = new Date();

const resetBrowserTimeZone = (date, timeZone) => {
  const browserDate = moment(date);
  const dateInTimeZone = moment.tz(date, timeZone);
  const offsetInMinutes = dateInTimeZone.utcOffset() - browserDate.utcOffset();
  const adjustedDate = new Date(browserDate.toDate().getTime() + offsetInMinutes * 60000);
  return adjustedDate;
};

const AvailabilityView = ({ project }) => {
  const { user } = useContext(DataContext);

  const defaultCurrentDate = resetBrowserTimeZone(today, user.timeZone);

  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(defaultCurrentDate);
  const events = project.calendar
    .filter((calendarDay) => calendarDay.entries.some((entry) => entry.status === 'Available'))
    .map((calendarDay) => {
      const availableTimeSlots = calendarDay.entries.filter(
        (entry) => entry.status === 'Available',
      );
      return {
        title: `${availableTimeSlots.length} slots`,
        start: new Date(calendarDay.date),
        end: new Date(calendarDay.date),
      };
    });
  const handleSelectDate = ({ start }) => setCurrentDate(start);
  const calendarEntry = project.calendar.filter(
    (day) => day.date
        === moment(currentDate).format('MM/DD/YYYY'),
  )[0];
  const timeSlots = calendarEntry?.entries.filter((entry) => entry.status === 'Available') || [];
  return (
    <Modal
      size="large"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={<CustomLink>{'see current availability >'}</CustomLink>}
      closeIcon
      id="availability-view"
    >
      <Modal.Content>
        <Grid stackable>
          <Grid.Column tablet={16} computer={11}>
            <Header>
              {`Available slots based on your selected timezone: ${user.timeZone}`}
              <Header.Subheader>
                {'You can change your availability '}
                <CustomLink onClick={() => { window.location.replace('/settings/availability'); }}>
                  <i style={{ textDecoration: 'underline' }}>here</i>
                </CustomLink>
              </Header.Subheader>
            </Header>
            <Calendar
              localizer={momentLocalizer(moment)}
              views={['month']}
              events={events}
              selectable
              onSelectSlot={handleSelectDate}
              onSelectEvent={handleSelectDate}
              getNow={() => currentDate}
              onNavigate={() => setCurrentDate(defaultCurrentDate)}
            />
          </Grid.Column>
          <Grid.Column tablet={16} computer={5}>
            <Header size="medium" textAlign="center">
              {moment(currentDate).format('dddd MMM Do, YYYY')}
            </Header>
            <Header size="small" textAlign="center">Available Time Slots</Header>
            <List>
              {timeSlots.length > 0
                ? timeSlots.map((timeSlot) => (
                  <List.Item key={timeSlot.startTime}>
                    <Label basic>
                      {moment.tz(timeSlot.startTime, user.timeZone).format('LT')}
                    </Label>
                  </List.Item>
                ))
                : (<Label basic>No available time slots</Label>)}
            </List>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default AvailabilityView;
